// Global constants for the whole app
import {
  ANALYTICS_ANONYMOUS_USER,
  getProductionEnvironment,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  guardNever,
} from "@hex/common";
import { ProviderConfig } from "launchdarkly-react-client-sdk";

import { logErrorMsg } from "./util/logging";

const LD_KEYS = {
  PROD: "5eab05c293bb130a701e54f0",
  STAGING: "5f48047f1f3feb08da94207b",
  DEVELOP: "5f4805a3ca98f809561d152b",
  LOCAL: "5eab05c293bb130a701e54ef",
  UNKNOWN: "5eab51ebcfea720a4dd058ac",
};

function getLDClientId(): string {
  const isProduction = process.env.NODE_ENV === "production";
  if (!isProduction) {
    return LD_KEYS.LOCAL;
  }

  const prodEnv = getProductionEnvironment(window.location.hostname);
  switch (prodEnv) {
    case "prod":
      return LD_KEYS.PROD;
    case "staging":
      return LD_KEYS.STAGING;
    case "develop":
      return LD_KEYS.DEVELOP;
    case "local":
      return LD_KEYS.LOCAL;
    // TODO: create new env for these and use them instead
    case "single-tenant":
    case "self-hosted":
    case "unknown":
      return LD_KEYS.UNKNOWN;
    default: {
      const err = guardNever(prodEnv, prodEnv);
      logErrorMsg(err, "Unrecognized production environment for LD client ID");
      return LD_KEYS.UNKNOWN;
    }
  }
}

// Needs to be kept in sync with server.ts
export const LD_CLIENT_ID = getLDClientId();

export const LD_CONFIG: ProviderConfig = {
  clientSideID: LD_CLIENT_ID,
  context: {
    kind: "user",
    key: ANALYTICS_ANONYMOUS_USER.id,
    name: ANALYTICS_ANONYMOUS_USER.name,
    email: ANALYTICS_ANONYMOUS_USER.email,
  },
  options: {
    // we disable client side events since we were rely on our own `Feature Flag
    // Evaluated` event instead.
    sendEventsOnlyForVariation: false,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
    // we rely on our own internal segment event implementation (Feature Flag
    // Evaluated) instead of LD event tracking
    sendEventsOnFlagRead: false,
  },
};

export const STORYBOOK_LD_CONFIG: ProviderConfig = {
  ...LD_CONFIG,
  context: { ...LD_CONFIG.context, key: "storybook" },
};
